// extracted by mini-css-extract-plugin
export var background_images = "banner-module__background_images___iSrn0";
export var banner = "banner-module__banner___XUp+d";
export var banner__content = "banner-module__banner__content___ve7ie";
export var banner__subtitle = "banner-module__banner__subtitle___QmtnH";
export var banner__title = "banner-module__banner__title___bikaX";
export var body_background = "#f8f9fa";
export var card = "banner-module__card___x3uGP";
export var chip = "banner-module__chip___s9wJW";
export var container = "banner-module__container___ak1cg";
export var content = "banner-module__content___9qYbp";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "banner-module__full_img___9bJ9V";
export var full_imgSmall = "banner-module__full_img--small___z5wnF";
export var gray_21 = "banner-module__gray_21___wxv1c";
export var image_container = "banner-module__image_container___aeUb1";
export var image_item = "banner-module__image_item___lKJY9";
export var lg = "1200px";
export var logo = "banner-module__logo___ZwgAr";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "banner-module__primary___EgK-Z";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "banner-module__title___es-yg";
export var typography_h1 = "banner-module__typography_h1___DkDk4";
export var typography_h2 = "banner-module__typography_h2___yFbSs";
export var typography_h3 = "banner-module__typography_h3___HtRjj";
export var white = "#fff";
export var white_button = "banner-module__white_button___JbfJe";
export var xl = "1536px";
export var xxl = "2500px";